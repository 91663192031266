/* quiz.css */

.quiz-container {
  max-width: 900px;
  margin: 40px auto;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

.question-text {
  font-size: 1.5em;
  margin-bottom: 30px;
  color: #444;
}

.answer-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.answer-section button {
  background-color: #ffb6c1;
  color: white;
  border: none;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.answer-section button:hover {
  background-color: #ff91a4;
  transform: translateY(-5px);
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.product-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.product-card img {
  width: 100%;
  height: auto;
}

.product-card p {
  padding: 10px;
  font-size: 0.9em;
  color: #333;
}

.product-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

.loading {
  font-size: 1.5em;
  color: #333;
}
