
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  font-size: 2em;
}

h1 {
  margin: 0;
}
